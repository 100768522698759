import {About} from './Components/About/About';
import {FaqSection} from './Components/FaqSection/FaqSection';
import {HeroComponent} from './Components/HeroComponent/HeroComponent';
import {MintSection} from './Components/MintSection/MintSection';
import {Roadmap} from './Components/Roadmap/Roadmap';
import {TeamPresentation} from './Components/TeamPresentation/TeamPresentation';
import {WalletAdapterNetwork} from "@solana/wallet-adapter-base";
import {clusterApiUrl} from "@solana/web3.js";
import {useMemo} from "react";
import {getPhantomWallet, getSolflareWallet, getSolletWallet} from "@solana/wallet-adapter-wallets";
import {MintingContextProvider} from "../../Components/Minting/MintingContext";
import {Zero} from './Components/Zero/Zero'
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const Home = () => {
    const endpoint = useMemo(() => clusterApiUrl(network), []);

    const wallets = useMemo(
        () => [getPhantomWallet(), getSolflareWallet(), getSolletWallet()],
        [],
    );
    return (
        <div>
            <HeroComponent className="pt-16 pb-20"/>
                        {/* <MintingContextProvider>
                            <MintSection className="py-20"/>
                        </MintingContextProvider> */}
            <About className="py-20"/>

            {/* <Roadmap className="py-20"/> */}

            <Zero className="py-20"/>
            {/* <FaqSection className="background-main"/> */}
        </div>
    );
    // return (
    //     <div>
    //         <HeroComponent className="pt-16 pb-20"/>
    //                     {/* <MintingContextProvider>
    //                         <MintSection className="py-20"/>
    //                     </MintingContextProvider> */}
    //         <About className="py-20, background-main"/>

    //         <Roadmap className="content-box, py-20, background-light"/>

    //         {/* <TeamPresentation className="py-20"/> */}

    //         <FaqSection className="py-20, content-box, background-main"/>
    //     </div>
    // );
}

export default Home;
