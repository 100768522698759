import {createContext, ReactElement, useEffect, useState} from "react";
import {ETH, Frame, IndexedHole, IndexedPainting, Painting, ShapeProps, Size, SOL} from "../helper/frames";
import {generateFrameOutput, generateTwitterBanner} from "./FrameOutputGenerator";
import {fetchAssets} from "./AssetFetcher";
import {generatePFPs, PFP} from "./FrameGeneratorPFP";
import _ from 'lodash'
import {useWeb3React} from "@web3-react/core";
import {fetchEthNFTs} from "../Metamask/EthAssetFetcher";

export const EDITOR_SIZE: Size = {
    width: 1024,
    height: 576
}

interface IFramesContext {
    walletConnected: boolean,
    loadingFrames: boolean,
    frames: Frame[],
    editedFrame?: Frame,
    openEditor: (f: Frame) => void,
    // EDITING SPACE
    paintings: Painting[],
    paintingPickerOpen: boolean,
    setPaintingPickerOpen: (b: boolean) => void,
    allPlaceHolders: IndexedHole[],
    remainingPlaceholders: IndexedHole[],
    selectedPlaceholder?: IndexedHole,
    setSelectedPlaceholder: (h: IndexedHole) => void,
    selectedPaintings: IndexedPainting[],
    addSelectedPainting: (p: Painting) => void
    registerPaintingProps: (i: number, s: ShapeProps) => void
    // ACTIONS
    resetEditorAvailable: boolean,
    resetEditorState: () => void,
    randomizePaintings: () => void,
    downloadRoomAvailable: boolean
    downloadRoom: () => Promise<void>,
    downloadTwitterBanner: () => Promise<void>,
    // PFPs
    openPickerPFP: boolean,
    setOpenPickerPFP: (b: boolean) => void,
    PFPs: PFP[],
    buildPFPs: () => Promise<void>
}

export const FramesContext = createContext<IFramesContext>({
    walletConnected: false,
    loadingFrames: true,
    frames: [],
    openEditor: () => {
    },
    // EDITING SPACE
    paintings: [],
    paintingPickerOpen: false,
    setPaintingPickerOpen: () => {
    },
    allPlaceHolders: [],
    remainingPlaceholders: [],
    setSelectedPlaceholder: () => {
    },
    selectedPaintings: [],
    addSelectedPainting: () => {
    },
    registerPaintingProps: () => {
        console.log("chuj")
    },
    // ACTIONS
    resetEditorAvailable: false,
    resetEditorState: () => {
    },
    randomizePaintings: () => {
    },
    downloadRoomAvailable: false,
    downloadRoom: async () => {
    },
    downloadTwitterBanner: async () => {
    },
    // PFPs
    openPickerPFP: false,
    setOpenPickerPFP: () => {
    },
    PFPs: [],
    buildPFPs: async () => {
    }
})

export const FramesContextProvider = (props: {wallet: string, children: ReactElement}) => {

    // ETH
    const ethCtx = useWeb3React()

    const [loadingFrames, setLoadingFrames] = useState(true)
    const [frames, setFrames] = useState<Frame[]>([])
    const [editedFrame, setEditedFrame] = useState<Frame | undefined>(undefined)

    const [paintings, setPaintings] = useState<Painting[]>([])
    // EDITING SPACE
    const [paintingPickerOpen, setPaintingPickerOpen] = useState(false)

    const [allPlaceHolders, setAllPlaceHolders] = useState<IndexedHole[]>([])
    const [remainingPlaceholders, setRemainingPlaceholders] = useState<IndexedHole[]>([])
    const [selectedPlaceholder, setSelectedPlaceholder] = useState<IndexedHole | undefined>(undefined)
    const [selectedPaintings, setSelectedPaintings] = useState<IndexedPainting[]>([])

    const [paintingProps, setPaintingProps] = useState<Record<number, ShapeProps>>({})
    // ACTIONS
    const [downloadRoomAvailable, setDownloadRoomAvailable] = useState(false)
    const [openPickerPFP, setOpenPickerPFP] = useState(false)
    const [PFPs, setPFPs] = useState<PFP[]>([])

    const loadAssets = async () => {
        setLoadingFrames(true)

        const [frames, solPaintings] = await fetchAssets(props.wallet)
        setFrames(frames)
        setPaintings([...solPaintings, ...paintings.filter(it => it.origin != SOL)])

        setLoadingFrames(false)
    }

    const loadEthAssets = async () => {
        if (ethCtx.active && ethCtx.account) {
            const ethPaintings = await fetchEthNFTs(ethCtx.account!!)
            setPaintings([...ethPaintings, ...paintings.filter(it => it.origin != ETH)])
        }
    }

    useEffect(() => {
        loadAssets().then(() => console.log("Assets loaded"))
    }, [props.wallet])

    useEffect(() => {
        loadEthAssets().then(() => console.log("ETH assets loaded"))
    }, [ethCtx.account])

    const openEditor = (frame: Frame) => {
        setEditedFrame(frame)
        resetEditorState(frame)
    }

    const resetEditorState = (_frame?: Frame) => {
        const frame = _frame || editedFrame!

        const initialPlaceholders: IndexedHole[] = frame.meta.resized(EDITOR_SIZE)
            .placeholderPositions.map((hole, idx) => ({id: idx, hole: hole}))

        setPaintingPickerOpen(false)
        setAllPlaceHolders(initialPlaceholders)
        setRemainingPlaceholders(initialPlaceholders)
        setSelectedPlaceholder(undefined)
        setSelectedPaintings([])
        setPaintingProps({})
        setDownloadRoomAvailable(false)
        setPFPs([])
    }

    const randomizePaintings = () => {
        let randomPaintings: Painting[] = []
        while (randomPaintings.length < allPlaceHolders.length) {
            randomPaintings = randomPaintings.concat(
                _.sampleSize(paintings,
                    Math.min(
                        allPlaceHolders.length,
                        paintings.length,
                        allPlaceHolders.length - randomPaintings.length
                    )))
        }
        setSelectedPaintings(randomPaintings.map((painting, idx) => ({id: idx, painting})))
        setRemainingPlaceholders([])
        setDownloadRoomAvailable(true)
    }

    const addSelectedPainting = (painting: Painting) => {
        const holeIdx = selectedPlaceholder!.id
        setSelectedPaintings([
            ...selectedPaintings.filter(it => it.id !== holeIdx),
            {id: holeIdx, painting}
        ])

        const placeholders = remainingPlaceholders.filter(it => it.id !== holeIdx)
        setRemainingPlaceholders(placeholders)

        if (placeholders.length === 0) {
            setDownloadRoomAvailable(true)
        }
    }

    const registerPaintingProps = (holeIdx: number, shapeProps: ShapeProps) => {
        setPaintingProps({
            ...paintingProps,
            [holeIdx]: shapeProps
        })
    }

    const downloadRoom = async () => {
        const url = await generateFrameOutput(editedFrame!, selectedPaintings, paintingProps)
            .then(it => it.toDataURL("image/png"))

        const link = document.createElement('a')
        link.download = `Your ${editedFrame!.meta.name}`
        link.href = url
        link.click()
        link.remove()
    }

    const downloadTwitterBanner = async () => {
        const url = await generateTwitterBanner(editedFrame!, selectedPaintings, paintingProps)
            .then(it => it.toDataURL("image/png"))

        const link = document.createElement('a')
        link.download = `Your ${editedFrame!.meta.name} (twitter banner)`
        link.href = url
        link.click()
        link.remove()
    }

    const buildPFPs = async () => {
        if (downloadRoomAvailable && PFPs.length === 0) {
            setPFPs(await generatePFPs(editedFrame!, allPlaceHolders, selectedPaintings, paintingProps))
        }
    }

    return (
        <FramesContext.Provider value={{
            walletConnected: !!props.wallet,
            loadingFrames: loadingFrames,
            frames: frames,
            editedFrame: editedFrame,
            openEditor: openEditor,
            paintings: paintings,
            paintingPickerOpen: paintingPickerOpen,
            setPaintingPickerOpen: setPaintingPickerOpen,
            allPlaceHolders: allPlaceHolders,
            remainingPlaceholders: remainingPlaceholders,
            selectedPlaceholder: selectedPlaceholder,
            setSelectedPlaceholder: setSelectedPlaceholder,
            selectedPaintings: selectedPaintings,
            addSelectedPainting: addSelectedPainting,
            registerPaintingProps: registerPaintingProps,
            resetEditorAvailable: !!editedFrame,
            resetEditorState: () => resetEditorState(),
            randomizePaintings: randomizePaintings,
            downloadRoomAvailable: downloadRoomAvailable,
            downloadRoom: downloadRoom,
            downloadTwitterBanner: downloadTwitterBanner,
            openPickerPFP: openPickerPFP,
            setOpenPickerPFP: setOpenPickerPFP,
            PFPs: PFPs,
            buildPFPs: buildPFPs
        }}>
            {props.children}
        </FramesContext.Provider>
    )
}
