import firebase from "firebase";
import {RoomMetadata} from "./frames";

const firebaseConfig = {
    apiKey: 'AIzaSyBff9hglOb9QO9cqFl5pCDV13C_dfthm5I',
    authDomain: 'artpunks-539e3.firebaseapp.com',
    projectId: 'artpunks-539e3',
    storageBucket: 'frames-nft',
    messagingSenderId: '784177245411',
    appId: '1:784177245411:web:3417b8fc1b4050925d29d6',
    measurementId: 'G-R5P8G2DV8R',
};

export type StorageCollection = 'artpunk_formula' | 'prod/traits/Wall' | 'prod/traits/Frame'

export type FirestoreCollection =
    'frames-nft-test'
    | 'frames-nft-prod'
    | 'artpunk-holders'
    | 'frames-config'
    | 'frames-config-test'
    | 'frames-nft-traits-prod'

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app(); // if already initialized, use that one
}

const storage = firebase.storage();

export const getImageDownloadURL = (collection: StorageCollection, id: number): Promise<string> =>
    getDownloadURL(`${collection}/${id}.png`)

export const getDownloadURL = (path: string): Promise<string> => storage.ref(path).getDownloadURL()

const db = firebase.firestore()

export const getDocument = async (collection: FirestoreCollection, id: any) =>
    db.doc(`${collection}/${id}`).get().then(it => it.data())

export const increment = async (collection: FirestoreCollection, id: any, field: string, value: number): Promise<any> => {
    const doc = db.doc(`${collection}/${id}`)

    await doc.update({
        [field]: firebase.firestore.FieldValue.increment(value)
    })

    return await getDocument(collection, id)
}

export const getRoomMetadata =(id: number) =>
    getDocument('frames-nft-prod', id).then(it => new RoomMetadata(it!))
