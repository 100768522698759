import { FC } from 'react';
import { SectionWithTitle } from '../../../../Components/SectionWithTitle/SectionWithTitle';
import classNames from 'classnames';

interface ZeroProps {
  className?: string;
}

export const Zero: FC<ZeroProps> = ({ className = '' }) => {
  return (
    <SectionWithTitle
      id="zero"
      title=""
      className={classNames(className, 'background-light')}
    >
                <div className="mb-6 text-center">
            <h2 className="text-5xl font-bold text-white">{'0.00%'}</h2>
            <h4 className="text-2xl font-bold text-white">{'Yeah, since 09/07/2022 FramesNFT have 0% royalties! Have fun!'}</h4>
          </div>
      {/* <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-12 gap-y-12">
        {aboutArtPunksDataList.map((item) => (
          <article
            key={item.title}
            className="flex flex-col items-center md:items-start gap-4"
          >
            <Image
              src={item.icon.src}
              alt={item.icon.alt}
              className="w-16 h-16"
            />

            <h5 className="text-h5 text-cyan tracking-widest">{item.title}</h5>

            <div className="text-familyPrimary">{item.description}</div>
          </article>
        ))}
      </div> */}
    </SectionWithTitle>
  );
};
