import * as React from "react";
import {useContext} from "react";
import {FramesContext} from "./FramesContext";
import DialogTitle from "@mui/material/DialogTitle";
import {Box} from "@mui/material";
import {FixedSizeList, ListChildComponentProps} from "react-window";
import Dialog from "@mui/material/Dialog";
import {CircularProgress} from "@material-ui/core";
import ListItem from "@mui/material/ListItem";
import GridLayout from "react-grid-layout";
import {LoadableImage} from "./LoadableImage";
import ListItemText from "@mui/material/ListItemText";

const ITEM_SIZE = 96

export const PickerPFP = () => {

    const {openPickerPFP, setOpenPickerPFP, PFPs, buildPFPs} = useContext(FramesContext)

    if (openPickerPFP) {
        buildPFPs().then(() => console.log("PFPs loaded"));
    }

    if (PFPs.length === 0) {
        return <Dialog open={openPickerPFP} onBackdropClick={() => setOpenPickerPFP(false)}>
            <DialogTitle>Pick PFP</DialogTitle>
            <Box sx={{width: '100%', height: 600, maxWidth: 400, bgcolor: 'background.paper'}}>
                <div style={{
                    width: 400,
                    height: 600,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <CircularProgress/>
                </div>
            </Box>
        </Dialog>
    }

    const renderRow = (props: ListChildComponentProps) => {
        const {index, style} = props;
        const PFP = PFPs[index]

        const uri = PFP.canvas.toDataURL("image/png")

        const onClick = () => {
            const link = document.createElement('a')
            link.download = PFP.name
            link.href = uri
            link.click()
            link.remove()
        }

        return (
            <ListItem button style={style} onClick={onClick} key={PFP.name}>
                <GridLayout className="pfp-picker-item" cols={5} rowHeight={ITEM_SIZE} width={360}
                            isResizable={false} isDraggable={false}>
                    <div key="image" data-grid={{x: 0, y: 0, w: 1, h: 1, static: true}}>
                        <LoadableImage uri={uri} width={ITEM_SIZE} height={ITEM_SIZE}/>
                    </div>
                    <div key="name" data-grid={{x: 2, y: 0, w: 3, h: 1, static: true}} style={{
                        width: ITEM_SIZE,
                        height: ITEM_SIZE,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <ListItemText primary={PFP.name}/>
                    </div>
                </GridLayout>
            </ListItem>
        )
    }

    return (
        <Dialog open={openPickerPFP} onBackdropClick={() => setOpenPickerPFP(false)}>
            <DialogTitle>Pick PFP</DialogTitle>
            <Box sx={{width: '100%', height: 600, maxWidth: 400, bgcolor: 'background.paper'}}>
                <FixedSizeList
                    height={600}
                    width={400}
                    itemData={PFPs}
                    itemSize={ITEM_SIZE + 16}
                    itemCount={PFPs.length}
                >
                    {renderRow}
                </FixedSizeList>
            </Box>
        </Dialog>
    )
}
