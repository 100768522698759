import { css } from '@emotion/css';
import classNames from 'classnames';
import { FC } from 'react';
import { Buttons } from './Buttons/ Buttons';
import { Header } from './Header/Header';
import heroBackground from './hero-background.jpg';

interface HeroComponentProps {
  className?: string;
}

const wrapper = css`
  background-image: url(${heroBackground});
  background-size: cover;
`;

export const HeroComponent: FC<HeroComponentProps> = ({ className = '' }) => {
  return (
    <div className={classNames(wrapper, className)}>
      <div className="content-box">
        <div className="flex flex-col w-full md:text-left">
          <Header />

          <Buttons />
        </div>
      </div>
    </div>
  );
};
