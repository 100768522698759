import { Image } from '../../../../../Components/Image/Image';
import frame1 from './hero-frame-1.png';
import frame2 from './hero-frame-2.png';
import frame3 from './hero-frame-3.png';
import frame4 from './hero-frame-4.png';

const renderFrame = (src: string) => (
  <Image
    src={src}
    alt="Example frame"
    className="md:w-40 md:h-40 lg:w-52 lg:h-52"
  />
);

export const Header = () => (
  <div className="flex flex-col md:flex-row flex-wrap items-center gap-8">
    <div>
      <h1 className="text-5xl font-sans text-white font-bold">Frames NFT</h1>

      <p className="text-white text-familyPrimary font-bold text-xl">
        {'Display your NFTs in a cooler way & trade with 0.00% royalities!'}
      </p>
      <p className="text-white text-familyPrimary font-bold text-xl">

      </p>
    </div>

    <div className="hidden md:flex flex-row flex-wrap gap-2 justify-center">
      {renderFrame(frame1)}

      {renderFrame(frame2)}

      {renderFrame(frame3)}

      {renderFrame(frame4)}
    </div>
  </div>
);
