import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {IndexedHole, Painting, ShapeProps, Size} from "../helper/frames";
import * as Images from "konva/lib/shapes/Image";
import {Image, Transformer} from 'react-konva';
import * as Trans from "konva/lib/shapes/Transformer";
import Konva from "konva";
import useImage from "use-image";
import {changeCursor, getCrop} from "./konva-utils";
import {FramesContext} from "./FramesContext";


interface EditablePaintingProps {
    indexedHole: IndexedHole,
    painting: Painting,
    selectedPlaceholder?: IndexedHole,
    setSelectedPlaceHolder: (p: IndexedHole) => void
}

export const EditablePainting = (props: EditablePaintingProps) => {

    const {indexedHole, painting, selectedPlaceholder, setSelectedPlaceHolder} = props
    const {id, hole} = indexedHole

    const isSelected = selectedPlaceholder?.id === id

    const { registerPaintingProps } = useContext(FramesContext)

    const [image] = useImage(painting.uri)

    const [shapeProps, setShapeProps] = useState<ShapeProps>({
        ...hole,
        width: hole.w,
        height: hole.h
    })
    const [cropProps, setCropProps] = useState<{ cropX?: number, cropY?: number, cropWidth?: number, cropHeight?: number }>({})

    const imageRef = useRef<Images.Image>();
    const trRef = useRef<Trans.Transformer>();

    const applyImageCrop = useCallback(() => {
        const node = imageRef.current!;
        const image = node.image()
        if (image) {
            const crop = getCrop(node.image()! as Size, {width: node.width(), height: node.height()})
            setCropProps(crop)
        }
    }, [image])

    useEffect(() => {
        if (isSelected) {
            trRef.current!.nodes([imageRef.current!]);
            trRef.current!.getLayer()!.batchDraw();
        }
        applyImageCrop();
        registerPaintingProps(id, shapeProps);
    }, [isSelected, imageRef, trRef, applyImageCrop]);


    const registerImageProps = (e: Konva.KonvaEventObject<Event>) => {
        const node = e.currentTarget!
        registerPaintingProps(id, {x: node.x(), y: node.y(), width: node.width(), height: node.height()})
    }

    return <React.Fragment>
        <Image
            // @ts-ignore
            ref={imageRef}
            uri={painting.uri}
            {...shapeProps}
            {...cropProps}
            onMouseEnter={changeCursor('pointer')}
            onMouseLeave={changeCursor('default')}
            onClick={() => setSelectedPlaceHolder(indexedHole)}
            onTap={() => setSelectedPlaceHolder(indexedHole)}
            draggable={true}
            onDragStart={() => setSelectedPlaceHolder(indexedHole)}
            onDragEnd={(e: Konva.KonvaEventObject<DragEvent>) => {
                setShapeProps({
                    ...shapeProps,
                    x: e.currentTarget.x(),
                    y: e.currentTarget.y()
                });
                registerImageProps(e);
            }
            }
            onTransform={() => {
                const node = imageRef.current!;
                const scaleX = node.scaleX();
                const scaleY = node.scaleY();

                node.scaleX(1);
                node.scaleY(1);
                setShapeProps({
                    ...shapeProps,
                    width: node.width() * scaleX,
                    height: node.height() * scaleY,
                })
                applyImageCrop()
            }}
            onTransformEnd={e => registerImageProps(e)}
            image={image}
        />
        {isSelected && (
            <Transformer
                // @ts-ignore
                ref={trRef}
                rotateEnabled={false}
            />
        )}
    </React.Fragment>
}
