import {Layer, Stage} from "react-konva";
import React, {useContext} from "react";
import {IndexedHole, Painting} from "../helper/frames";
import {changeCursor} from "./konva-utils";
import {PaintingPicker} from "./PaintingPicker";
import {IMAGE_URL} from "../helper/configs";
import {SimpleImage} from "./SimpleImage";
import {EDITOR_SIZE, FramesContext} from "./FramesContext";
import {EditablePainting} from "./EditablePainting";
import {PickerPFP} from "./PickerPFP";


const PLACEHOLDER_URI = `${IMAGE_URL}/frames/plus-on-canvas.jpg`

const PaintingPlaceholder = (props: { indexedHole: IndexedHole, onClick: (h: IndexedHole) => void }) => {
    const hole = props.indexedHole.hole

    return <SimpleImage uri={PLACEHOLDER_URI} x={hole.x} y={hole.y} width={hole.w} height={hole.h}
                        onMouseEnter={changeCursor('pointer')}
                        onMouseLeave={changeCursor('default')}
                        onClick={() => props.onClick(props.indexedHole)}/>
}

export const FrameEditor = () => {

    const ctx = useContext(FramesContext)

    const {
        editedFrame,
        paintingPickerOpen,
        setPaintingPickerOpen,
        allPlaceHolders,
        remainingPlaceholders,
        selectedPlaceholder,
        setSelectedPlaceholder,
        selectedPaintings,
        addSelectedPainting
    } = ctx

    const onPlaceholderClick = (hole: IndexedHole) => {
        setPaintingPickerOpen(true)
        setSelectedPlaceholder(hole)
    }

    const onPickerClose = (selectedPainting: Painting) => {
        setPaintingPickerOpen(false)
        addSelectedPainting(selectedPainting)
    }

    return <div style={{...EDITOR_SIZE}}>
        <Stage {...EDITOR_SIZE}>
            <FramesContext.Provider value={ctx}>
                <Layer>
                    {remainingPlaceholders.map(hole =>
                        <PaintingPlaceholder key={hole.id} indexedHole={hole} onClick={onPlaceholderClick}/>
                    )}
                    {selectedPaintings
                        .map(p => ({...p, indexedHole: allPlaceHolders.find(it => it.id === p.id)}))
                        .filter(p => p.indexedHole)
                        .map(({ id, painting, indexedHole }) =>
                        <EditablePainting
                            key={`painting ${id}`}
                            indexedHole={indexedHole!}
                            painting={painting}
                            selectedPlaceholder={selectedPlaceholder}
                            setSelectedPlaceHolder={setSelectedPlaceholder}/>
                    )}
                    <SimpleImage key={editedFrame?.meta.name} uri={editedFrame?.uri} {...EDITOR_SIZE} hitFunc={() => {
                    }}/>
                </Layer>
            </FramesContext.Provider>
        </Stage>
        <PaintingPicker
            open={paintingPickerOpen}
            onClose={onPickerClose}
        />
        <PickerPFP />
    </div>
}
