import {IRect} from "konva/lib/types";
import {ShapeConfig} from "konva/lib/Shape";
import useImage from "use-image";
import {Image, Layer, Stage} from "react-konva";
import React, {useEffect, useState} from "react";
import {CircularProgress} from "@material-ui/core";
import {getCrop} from "./konva-utils";

export const LoadableImage = (props: {
    uri: any,
    crop?: IRect
} & ShapeConfig) => {

    const [image, status] = useImage(props.uri)
    const [cropProps, setCropProps] = useState<{ cropX?: number, cropY?: number, cropWidth?: number, cropHeight?: number }>({})

    useEffect(() => {
        if (image) {
            const crop = getCrop(image, {width: props.width!, height: props.height!})
            setCropProps(crop)
        }
    }, [image])

    if (status === 'loading') {
        return <div style={{
            width: props.width,
            height: props.height,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <CircularProgress/>
        </div>
    }

    return <Stage width={props.width} height={props.height}>
        <Layer>
            <Image {...props} {...cropProps} image={image}/>
        </Layer>
    </Stage>
}
