// import {
//   WalletDialogButton,
//   WalletDisconnectButton,
// } from '@solana/wallet-adapter-material-ui';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import './Navbar.css';
import { NavLink } from 'react-router-dom';
import { FramesNftLogo } from '../FramesNftLogo/FramesNftLogo';
import classNames from 'classnames';
import { css } from '@emotion/css';
import {
  WalletDialogButton,
  WalletDisconnectButton,
} from '@solana/wallet-adapter-material-ui';
import { MetamaskButton } from '../Metamask/MetamaskButton';

export type NavItem = {
  title: string;
  to: string;
  newTab?: boolean;
  default?: boolean;
};

const icon = () => (
  <img height={24} width={24} src={'solana-64.png'} alt={'solana icon'} />
);

const menu: NavItem[] = [
  {
    title: 'Home',
    to: '/',
  },

  {
    title: 'About & Utility',
    to: '/#about',
  },

  {
    title: 'Roadmap',
    to: '/#roadmap',
  },

  {
    title: '0.00%',
    to: '/#zero',
  },

  {
    title: 'Frames Tool',
    to: '/your-frames',
  },

  // {
  //   title: 'Team',
  //   to: '/#team',
  // },

  // {
  //   title: 'FAQ',
  //   to: '/#faq',
  // },

  {
    title: 'Rarity',
    to: '/rarity',
  },
];

const Navbar = (props: any) => {
  const wallet = useAnchorWallet();

  if (wallet) {
    props.callbackFromParentWallet(wallet?.publicKey.toString());
  } else {
    props.callbackFromParentWallet('');
  }

  const renderNavLink = (navItem: NavItem) => (
    <NavLink
      exact
      className={(isActive) =>
        classNames('nav-menu-item menu-white-test', isActive && 'nav-menu-selected')
      }
      to={navItem.to}
      isActive={(match, location) => {
        if (location.pathname === '/' && navItem.to === '/' && location.hash) {
          return false;
        }

        const targetUrlHashIndex = navItem.to.indexOf('#');

        if (targetUrlHashIndex === -1 && match?.isExact) {
          return true;
        }

        if (
          targetUrlHashIndex > 0 &&
          location.hash === navItem.to.slice(targetUrlHashIndex) &&
          match?.isExact
        ) {
          return true;
        }

        return false;
      }}
    >
      {navItem.title}
    </NavLink>
  );

  const renderDefaultLink = (navItem: NavItem) => (
    <a
      href={navItem.to}
      target={navItem.newTab ? '_blank' : '_self'}
      rel="noreferrer"
      className="nav-menu-item text-body1"
    >
      {navItem.title}
    </a>
  );

  return (
    <div
      className={classNames(
        'flex justify-between items-center gap-2 z-30 sticky top-0 px-8 py-3 w-ful nav-background',
        css`
          box-shadow: 0rem 0.2rem 0.2rem rgb(0 0 0 / 30%);
        `,
      )}
    >
      <div
        className={css`
          flex-shrink: 0;
          align-self: center;
        `}
      >
        <FramesNftLogo />
      </div>

      <div className="nav-menu flex flex-row flex-wrap gap-x-8 md:gap-x-12 gap-y-2">
        {menu.map((navItem) => (
          <div key={navItem.title}>
            {navItem.default
              ? renderDefaultLink(navItem)
              : renderNavLink(navItem)}
          </div>
        ))}
      </div>

      <div>
        {wallet ? (
          <WalletDisconnectButton startIcon={icon()} className="connect-button">
            Disconnect
          </WalletDisconnectButton>
        ) : (
          <WalletDialogButton startIcon={icon()} className="connect-button">
            Connect
          </WalletDialogButton>
        )}
        <MetamaskButton className="connect-button" style={{ marginLeft: 16 }} />
      </div>
    </div>
  );
};

export default Navbar;
