import { css } from '@emotion/css';
import classNames from 'classnames';
import { Image } from '../Image/Image';
import iconDiscord from './icon-discord.png';
import iconTwitter from './icon-tw.png';
import logo from './logo-transparent.png';

const renderFooterLink = (content: any, href: string) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    className={css`
      text-decoration: none;
      color: white;
      display: block;

      &:hover {
        color: white;
      }
    `}
  >
    {content}
  </a>
);

export const Footer = () => {
  return (
    <div
      className={classNames(
        'flex justify-center px-16 py-8',
        css`
          background-color: #101010;
        `,
      )}
    >
      <div className="flex flex-col gap-2 items-center">
        <Image src={logo} className="w-32 h-32 mb-2" />
        <p className="text-white">
            Created by ArtPunks team
          </p>

          <div className="footer-sub-div">
          <a className="footer-desc-link"
          href="https://artpunks.fun/">
            ArtPunks website
            </a>
          <a className="footer-desc-link"
          href="https://www.magiceden.io/marketplace/artpunks">
            Buy ArtPunks
            </a>
          </div>

        <div className="flex flex-row gap-4">
          {renderFooterLink(
            <Image src={iconTwitter} className="footer-socials-logo" />,
            'https://twitter.com/nft_frames',
          )}

          {renderFooterLink(
            <Image src={iconDiscord} className="footer-socials-logo" />,
            'http://discord.gg/artpunks',
          )}
        </div>
      </div>
    </div>
  );
};
