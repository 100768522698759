import firebase from "firebase";

export const ROOM_RESOLUTION = {width: 1920, height: 1080}

export type Size = { width: number, height: number }
export type PlaceholderPosition = { x: number, y: number, w: number, h: number }
export type IndexedHole = { id: number, hole: PlaceholderPosition }

export type BlockchainOrigin = "sol" | "eth"
export const SOL: BlockchainOrigin = "sol"
export const ETH: BlockchainOrigin = "eth"
export type Painting = { name: string, uri: string, origin: BlockchainOrigin }
export type IndexedPainting = { id: number, painting: Painting }

export class RoomMetadata {
    private readonly source: firebase.firestore.DocumentData

    readonly name: string
    readonly size: Size
    readonly placeholderPositions: PlaceholderPosition[]

    constructor(source: firebase.firestore.DocumentData, _size?: Size, _placeholderPositions?: PlaceholderPosition[]) {
        this.source = source
        this.name = source.name

        if (_size && _placeholderPositions) {
            this.size = _size
            this.placeholderPositions = _placeholderPositions
        } else {
            this.size = ROOM_RESOLUTION
            this.placeholderPositions = source.placeholder_positions;
        }
    }

    resized = (targetRoomSize: Size): RoomMetadata => {
        const scaleRatio = targetRoomSize.width / this.size.width
        const scaledPlaceholders = this.placeholderPositions.map(it => ({
            x: Math.round(it.x * scaleRatio),
            y: Math.round(it.y * scaleRatio),
            w: Math.round(it.w * scaleRatio),
            h: Math.round(it.h * scaleRatio)
        }))
        return new RoomMetadata(this.source, targetRoomSize, scaledPlaceholders)
    }
}

export type Frame = { uri: any, meta: RoomMetadata, json: any }

export type ShapeProps = { x: number, y: number } & Size
