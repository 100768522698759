import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import {useContext, useMemo, useState} from 'react';
import {Painting} from "../helper/frames";
import GridLayout from "react-grid-layout";
import {LoadableImage} from "./LoadableImage";
import {Box} from '@mui/material';
import {FixedSizeList, ListChildComponentProps} from 'react-window';
import {FramesContext} from "./FramesContext";
import SearchBar from "material-ui-search-bar";


const PAINTING_SIZE = 96

export const PaintingPicker = (props: { open: boolean, onClose: (p: Painting) => void }) => {
    const {onClose, open} = props;

    const {paintings, setPaintingPickerOpen} = useContext(FramesContext)

    const [searchPhrase, setSearchPhrase] = useState<string | undefined>(undefined)

    const filteredPaintings: Painting[] = useMemo(() => {
        const phrase = searchPhrase?.trim()

        if (!phrase || phrase === '') {
            return paintings
        }
        return paintings
            .filter(it => !!it.name)
            .filter(it => it.name.toLowerCase().includes(phrase.toLowerCase()))

    }, [searchPhrase, paintings])


    const handleListItemClick = (painting: Painting) => {
        onClose(painting);
    };

    const renderRow = (props: ListChildComponentProps) => {
        const {index, style} = props;
        const painting = filteredPaintings[index]

        return (
            <ListItem button style={style} onClick={() => handleListItemClick(painting)} key={painting.uri}>
                <GridLayout className="painting-picker-item" cols={5} rowHeight={PAINTING_SIZE} width={360}
                            isResizable={false} isDraggable={false}>
                    <div key="image" data-grid={{x: 0, y: 0, w: 1, h: 1, static: true}}>
                        <LoadableImage uri={painting.uri} width={PAINTING_SIZE} height={PAINTING_SIZE}/>
                    </div>
                    <div key="name" data-grid={{x: 2, y: 0, w: 3, h: 1, static: true}} style={{
                        width: PAINTING_SIZE,
                        height: PAINTING_SIZE,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <ListItemText primary={painting.name}/>
                    </div>
                </GridLayout>
            </ListItem>
        )
    }

    return (
        <Dialog open={open} onBackdropClick={() => setPaintingPickerOpen(false)}>
            <DialogTitle>Pick NFT</DialogTitle>
            <SearchBar value={searchPhrase} onChange={setSearchPhrase} onCancelSearch={() => setSearchPhrase("")}/>
            <Box sx={{width: '100%', height: 600, maxWidth: 400, bgcolor: 'background.paper'}}>
                <FixedSizeList
                    height={600}
                    width={400}
                    itemData={filteredPaintings}
                    itemSize={PAINTING_SIZE + 16}
                    itemCount={filteredPaintings.length}
                >
                    {renderRow}
                </FixedSizeList>
            </Box>
        </Dialog>
    );
};
