import {IRect} from "konva/lib/types";
import {ShapeConfig} from "konva/lib/Shape";
import useImage from "use-image";
import {Image} from "react-konva";
import React from "react";

export const SimpleImage = (props: { uri: any, crop?: IRect } & ShapeConfig) => {
    const [image] = useImage(props.uri)

    return <Image id={props.uri} {...props} image={image}/>
}
