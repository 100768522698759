import Konva from "konva";
import {Size} from "../helper/frames";

export const changeCursor = (style: 'pointer' | 'default'): ((evt: Konva.KonvaEventObject<MouseEvent>) => void) =>
    (
        event => {
            const container = event.target.getStage()?.container();
            if (container) {
                container.style.cursor = style;
            }
        }
    )

export const getCrop = (image: Size, container: Size) => {
    const width = container.width;
    const height = container.height;
    const aspectRatio = width / height;

    let newWidth;
    let newHeight;

    const imageRatio = image.width / image.height;

    if (aspectRatio >= imageRatio) {
        newWidth = image.width;
        newHeight = image.width / aspectRatio;
    } else {
        newWidth = image.height * aspectRatio;
        newHeight = image.height;
    }

    const x = (image.width - newWidth) / 2;
    const y = (image.height - newHeight) / 2;

    return {
        cropX: x,
        cropY: y,
        cropWidth: newWidth,
        cropHeight: newHeight,
    };
};
