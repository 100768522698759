import {getNFTs} from "../helper/getAccountNFTs";
import {Data} from "../helper/Metadata.service";
import {getRoomMetadata} from "../helper/FirebaseFetcher";
import {Frame, Painting, SOL} from "../helper/frames";
import axios from "axios";
import _ from "lodash";

export const fetchAssets = async (wallet: string): Promise<[Frame[], Painting[]]> => {
  //console.log('LOG 0')
    const allNFTs: (Data & { json: any })[] = await getNFTs(wallet)
        .then(metas => Promise.all(metas
                .map(it => it.data)
                .map(it => axios(it.uri)
                    .then(rs => ({...it, json: rs.data}))
                    .catch(er => {
                        console.error(er)
                        return { ...it, json: {} }
                    }))
            )
        ).then(results => {
            //console.log(`Number of fetched NFTs: ${results.length}`);
            return results.filter(result => result.json !== undefined);
        });

    //console.log('LOG 3.2')
    // TODO: match 'creators' too
    const frameNFTs = allNFTs.filter(it => it.symbol === 'FRAME' && it.name.startsWith('Frame '))
    const otherNFTs = allNFTs.filter(it => it.symbol !== 'FRAME')
    //console.log('LOG 4')

    const frames = frameNFTs.map(it => {
        const id = parseInt(it.name.split('#')[1])

        return getRoomMetadata(id).then(meta => ({
            uri: it.json.image,
            meta: meta,
            json: it.json
        }))
    })
    //console.log('LOG 5')

    const paintings = otherNFTs.map(it => ({name: it.name, uri: it.json.image, origin: SOL}))
    //console.log('LOG 6')

    return [await Promise.all(frames), paintings]
}
