import { FC, ReactNode } from 'react';
import classNames from 'classnames';

interface SectionWithTitleProps {
  id?: string;
  title: ReactNode;
  className?: string;
}

export const SectionWithTitle: FC<SectionWithTitleProps> = ({
  id,
  title,
  children,
  className = '',
}) => (
  <section
    id={id}
    className={classNames(className, 'flex flex-col items-center')}
  >
    <div className="mb-12">
      <h2 className="text-familyPrimary text-5xl">{title}</h2>
    </div>

    {children}
  </section>
);
