import { css } from '@emotion/css';
import classNames from 'classnames';
import { FC, MouseEvent } from 'react';

type ButtonVariant = 'primary' | 'outlineWhite' | 'white' | 'blue';

type ButtonRoundedVariant = 'normal' | 'full';

interface CustomLink {
  href: string;
  isExternal?: boolean;
}

interface ButtonProps {
  variant: ButtonVariant;
  rounded?: ButtonRoundedVariant;
  bold?: boolean;
  link?: CustomLink;
  className?: string;
  onClick?: (e: MouseEvent) => void;
}

const variantClasses: Record<ButtonVariant, string> = {
  primary: 'bg-violet-bright hover:bg-violet-brighter text-white px-8',
  outlineWhite: 'bg-transparent border-white text-white px-8',
  white: 'bg-white border-white text-black px-16',
  blue: classNames(
    css`
      background-color: #dc1fff;
      color: white;

      &:hover {
        color: white;
      }
    `,
    'px-16',
  ),
};

const roundedVariantClasses: Record<ButtonRoundedVariant, string> = {
  full: 'rounded-full',
  normal: 'rounded-md',
};

export const Button: FC<ButtonProps> = ({
  variant,
  rounded = 'normal',
  bold = false,
  link,
  children,
  className = '',
  onClick,
}) => {
  const sharedClasses = classNames(
    'py-4 text-familyPrimary',
    bold && 'font-bold',
    css`
      text-decoration: none;
    `,
  );

  const renderContent = () => children;

  const fullClassName = classNames(
    sharedClasses,
    variantClasses[variant],
    roundedVariantClasses[rounded],
    className,
  );

  if (link) {
    return (
      <a
        href={link.href}
        target={link.isExternal ? '_blank' : '_self'}
        rel="noreferrer"
        className={fullClassName}
        onClick={onClick}
      >
        {renderContent()}
      </a>
    );
  }

  return (
    <button className={fullClassName} onClick={onClick}>
      {renderContent()}
    </button>
  );
};
