import { Button } from '../../../../../Components/Button/Button';
import ReactGA from 'react-ga';

export const Buttons = () => (
  <div className="flex flex-col md:flex-row flex-wrap gap-4 items-center md:items-start mt-16">
    <Button
      variant="blue"
      rounded="normal"
      bold
      link={{
        href: 'https://www.magiceden.io/marketplace/framesnft',
        isExternal: true,
      }}
      onClick={() => {
        ReactGA.event({
          category: 'Hero Component',
          action: 'Mint Click',
        });
      }}
    >
      Buy a FrameNFT
    </Button>
    <Button
      variant="blue"
      rounded="normal"
      bold
      link={{
        href: '/your-frames',
        isExternal: false,
      }}
      onClick={() => {
        ReactGA.event({
          category: 'Hero Component',
          action: 'Frame tool',
        });
      }}
    >
      Use Frame Tool
    </Button>

    <Button
      variant="white"
      rounded="normal"
      link={{
        href: 'https://twitter.com/nft_frames',
        isExternal: true,
      }}
      onClick={() => {
        ReactGA.event({
          category: 'Hero Component',
          action: 'Go To Twitter',
        });
      }}
    >
      Follow us on Twitter
    </Button>

    <Button
      variant="white"
      rounded="normal"
      link={{
        href: 'http://discord.gg/artpunks',
        isExternal: true,
      }}
      onClick={() => {
        ReactGA.event({
          category: 'Hero Component',
          action: 'Go To Discord',
        });
      }}
    >
      Join our Discord
    </Button>
  </div>
);
