import {EDITOR_SIZE, FramesContext} from "./FramesContext";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import {LoadableImage} from "./LoadableImage";
import {Paper} from "@material-ui/core";
import React, {useContext, useMemo} from "react";
import {FixedSizeList, ListChildComponentProps} from 'react-window';

const frameListItemSize = {
    width: 256,
    height: 144
}

export const FramePicker = () => {

    const {
        frames,
        openEditor,
    } = useContext(FramesContext)

    const renderRow = (props: ListChildComponentProps) => {
        const {index, style} = props;
        const frame = frames[index]

        return <Tooltip title={frame.meta.name} followCursor enterDelay={1000}
                        enterNextDelay={500}>
            <ListItem button style={style} key={frame.meta.name}
                      onClick={() => openEditor(frame)}>
                <LoadableImage uri={frame.uri}
                               tooltip={frame.meta.name}
                               {...frameListItemSize}/>
            </ListItem>
        </Tooltip>

    }

    const renderList = () => {
        return <Paper style={{
            maxHeight: EDITOR_SIZE.height,
            maxWidth: frameListItemSize.width + 48,
            overflow: 'auto',
            backgroundColor: '#101010'
        }}>
            <FixedSizeList
                height={EDITOR_SIZE.height}
                width={frameListItemSize.width + 48}
                itemData={frames}
                itemSize={frameListItemSize.height + 16}
                itemCount={frames.length}
            >
                {renderRow}
            </FixedSizeList>
        </Paper>
    }

    const list = useMemo(() => renderList(), [frames])

    return list
}
