import {ETH, Painting} from "../helper/frames";
import axios from "axios";
import _ from 'lodash'

interface Token {
    contractAddress: string,
    tokenID: string,

    from: string,
    to: string,
    timeStamp: string
}

export const fetchEthNFTs = async (address: string): Promise<Painting[]> => {
    const transactions = await getERC721Transactions(address)
    const ownedTokens = filterOwnedTokens(address, transactions)

    let paintings: Painting[] = []
    for (const chunk of _.chunk(ownedTokens, 5)) {
        paintings = [...paintings, ...await Promise.all(chunk.map(t => getTokenImage(t.contractAddress, t.tokenID)))]
    }
    return paintings
}

const getERC721Transactions = (address: string): Promise<Token[]> => {
    const url = `https://api.etherscan.io/api?module=account&action=tokennfttx&address=${address}&startblock=0&endblock=999999999&sort=asc&apikey=${"SDHZ9FPMUK55TY5KAU2FBS222DKPIITD65"}`
    return axios(url).then((resp: any) => resp.data.result)
}

const getTokenImage = (contractAddress: string, tokenID: string): Promise<Painting> => {
    const url = `https://api.opensea.io/api/v1/asset/${contractAddress}/${tokenID}`
    return axios(url).then((rs: any) => ({
        name: rs.data.name,
        uri: rs.data.image_url,
        origin: ETH
    }))
}

const filterOwnedTokens = (address: string, tokens: Token[]): Token[] => {

    const transactionsByToken: Record<string, Token[]> = _.groupBy(tokens, it => `${it.contractAddress}/${it.tokenID}`)

    return Object.values(transactionsByToken)
        .map(transactions => _.sortBy(transactions, tx => tx.timeStamp).reverse())
        .flatMap(tokenTransactions => [tokenTransactions[0]])
        .filter(tx => tx.to.toLowerCase() === address.toLowerCase())
}
