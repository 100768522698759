import classNames from "classnames";
import {css} from "@emotion/css";
import {Button} from "../Button/Button";

export const RarityPDF = () => {
    return <div style={{margin: 64, justifyContent: 'center', flex: 1, display: 'flex'}}>

<Button
            variant="white"
            rounded="normal"
            onClick={() => {window.open('https://howrare.is/framesnft', '_blank');}}
            className={classNames(
                'mt-auto',
                css`
              box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
              font-size: 2rem;
              margin-right: 12px;
  margin-left: 12px;
            `,
            )}
        >
            🔎 Official Rarity (howrare.is)
        </Button>

        <Button
            variant="white"
            rounded="normal"
            onClick={() => window.open('frame-nft-rarities.pdf')}
            className={classNames(
                'mt-auto',
                css`
              box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
              font-size: 2rem;
              margin-right: 12px;
              margin-left: 12px;
            `,
            )}
        >
            📜 View Rarities PDF
        </Button>
    </div>
}
