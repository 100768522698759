import './YourFrames.css'
import React, {useContext} from "react";
import GridLayout from 'react-grid-layout';
import {CircularProgress} from "@material-ui/core";
import {FrameEditor} from "./FrameEditor";
import {FramesContext} from "./FramesContext";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {FramePicker} from "./FramePicker";

export const YourFrames = () => {

    const {
        walletConnected,
        loadingFrames,
        frames,
        resetEditorAvailable,
        resetEditorState,
        randomizePaintings,
        downloadRoomAvailable,
        downloadRoom,
        downloadTwitterBanner,
        setOpenPickerPFP
    } = useContext(FramesContext)

    if (!walletConnected) {
        return <div className="background-main">
            <p className="connect-your-wallet">Please connect your Solana wallet.</p>
<p className="tutorial">Frames Tool Tutorial:</p>
<div
            style={{
              paddingTop: '56.25%',
              position: 'relative',
              justifyContent: 'center',
              margin: 48,
              marginLeft: 148,
              marginRight: 148,
            }}
          >
            <iframe
              width="100%"
              height="60%"
              src="https://www.youtube.com/embed/CtaOU8rgoEk"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute inset-0 w-full col-centered"
            ></iframe>
          </div>
        </div>
    }
    if (walletConnected && loadingFrames) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 64
        }}>
            <CircularProgress/>
        </div>
    }
    if (walletConnected && !loadingFrames && frames.length === 0) {
        return <div>
            <p className="connect-your-wallet">No frames detected</p>
        </div>
    }

    return (
        <>
            <div>
                <div className="frame-gen-main">
                    <p className="frame-h">Your FrameNFTs</p>
                    {!loadingFrames && (
                        <GridLayout className="layout" cols={11} width={11 * 128} rowHeight={72} margin={[0, 0]}
                                    isDraggable={false} isResizable={false}>
                            <div key="room-picker" data-grid={{x: 0, y: 0, w: 3, h: 8, static: true}}>
                                <FramePicker/>
                            </div>
                            <div key="editor" data-grid={{x: 3, y: 0, w: 8, h: 8, static: true}}>
                                <FrameEditor/>
                            </div>
                            <div key="action-pfp" data-grid={{x: 3, y: 8, w: 2, h: 1, static: true}}>
                                <Stack spacing={3} direction="row" style={{justifyContent: 'left', marginTop: 16}}>
                                    <Button variant="contained" disabled={!resetEditorAvailable} color="secondary"
                                            onClick={resetEditorState}>Reset</Button>
                                    <Button variant="contained" disabled={!resetEditorAvailable} color="secondary"
                                            onClick={randomizePaintings}>Randomize</Button>
                                </Stack>
                            </div>
                            <div key="action-buttons" data-grid={{x: 8, y: 8, w: 3, h: 1, static: true}}>
                                <Stack spacing={3} direction="row" style={{justifyContent: 'right', marginTop: 16}}>
                                    <Button variant="contained" disabled={!downloadRoomAvailable} color="secondary"
                                            onClick={() => setOpenPickerPFP(true)}>
                                        PFP
                                    </Button>
                                    <Button variant="contained" disabled={!downloadRoomAvailable} color="secondary"
                                            onClick={downloadTwitterBanner}>
                                        Twitter banner
                                    </Button>
                                    <Button variant="contained" disabled={!downloadRoomAvailable} color="secondary"
                                            onClick={downloadRoom}>
                                        Full Size
                                    </Button>
                                </Stack>
                            </div>
                        </GridLayout>
                    )}
                </div>
            </div>
        </>
    )
}
