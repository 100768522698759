import {Frame, IndexedPainting, ShapeProps, Size} from "../helper/frames";
import {Canvas, createCanvas, Image, loadImage, NodeCanvasRenderingContext2D} from 'canvas';
import {EDITOR_SIZE} from "./FramesContext";
import {getCrop} from "./konva-utils";
import {getDownloadURL, StorageCollection} from "../helper/FirebaseFetcher";

const OUTPUT_RESOLUTION: Size = {
    width: 1920,
    height: 1080
}

const WALL_DIR: StorageCollection = 'prod/traits/Wall'

const rescaleShapeProps = (shape: ShapeProps): ShapeProps => {
    const ratio = OUTPUT_RESOLUTION.width / EDITOR_SIZE.width

    return ({
        x: Math.round(shape.x * ratio),
        y: Math.round(shape.y * ratio),
        width: Math.round(shape.width * ratio),
        height: Math.round(shape.height * ratio),
    })
}

export const generateFrameOutput = async (frame: Frame,
                                          selectedPaintings: IndexedPainting[],
                                          paintingProps: Record<number, ShapeProps>): Promise<Canvas> => {

    const canvas = createCanvas(OUTPUT_RESOLUTION.width, OUTPUT_RESOLUTION.height)
    const ctx = canvas.getContext('2d')

    for (const {id, painting} of selectedPaintings) {
        const image = await loadImage(painting.uri, {crossOrigin: "anonymous"})

        const shape = rescaleShapeProps(paintingProps[id])
        const { cropX, cropY, cropWidth, cropHeight } = getCrop(image, shape)

        ctx.drawImage(image, cropX, cropY, cropWidth, cropHeight, shape.x, shape.y, shape.width, shape.height)
    }

    const roomLayer = await loadImage(frame.uri, {crossOrigin: "anonymous"})
    ctx.drawImage(roomLayer, 0, 0)

    return canvas
}

export const fetchWallUri = async (frame: Frame): Promise<Image> => {
    const wall = frame.json?.attributes.filter((a: any) => a.trait_type === "Wall").map((a: any) => a.value)[0]
    if (!wall) {
        console.error("could not resolve wall ID")
    }
    return await getDownloadURL(`${WALL_DIR}/${wall}.png`)
        .then(uri => loadImage(uri, {crossOrigin: "anonymous"}))
}

const OUT_ROOM_W = 1088
const OUT_ROOM_H = 612
const OUT_WALL_W = OUT_ROOM_W
const OUT_WALL_H = 521
const BLUR_FADE = 30

const drawFlipped = (ctx: NodeCanvasRenderingContext2D, img: Image, x: number, y: number, w: number, h: number) => {
    ctx.save()
    // Set the origin to the center of the image
    ctx.translate(x + w/2, y + h/2);
    ctx.scale(-1, 1);

    ctx.drawImage(img, -w/2, -h/2, w, h);

    ctx.restore();
}

const generateBannerBase = async (frame: Frame,
                                  selectedPaintings: IndexedPainting[],
                                  paintingProps: Record<number, ShapeProps>): Promise<Canvas> => {

    const [wallImage, roomCanvas] = await Promise.all([
        fetchWallUri(frame),
        generateFrameOutput(frame, selectedPaintings, paintingProps)
    ])

    const canvas = createCanvas(3 * OUT_ROOM_W, 500)
    const ctx = canvas.getContext('2d')

    ctx.drawImage(roomCanvas, OUT_WALL_W, 0, OUT_ROOM_W, OUT_ROOM_H)

    drawFlipped(ctx, wallImage, 0, 0, OUT_WALL_W, OUT_WALL_H)
    drawFlipped(ctx, wallImage, 2 * OUT_WALL_W, 0, OUT_WALL_W, OUT_WALL_H)

    ctx.filter = 'blur(4px)';

    ctx.drawImage(canvas,
        0, 0, OUT_WALL_W + BLUR_FADE, OUT_WALL_H,
        0, 0, OUT_WALL_W + BLUR_FADE, OUT_WALL_H
    )

    ctx.drawImage(canvas,
        OUT_ROOM_W * 2 - BLUR_FADE, 0, OUT_WALL_W + BLUR_FADE, OUT_WALL_H,
        OUT_ROOM_W * 2 - BLUR_FADE, 0, OUT_WALL_W + BLUR_FADE, OUT_WALL_H
    )

    return canvas
}

export const generateTwitterBanner = async (frame: Frame,
                                     selectedPaintings: IndexedPainting[],
                                     paintingProps: Record<number, ShapeProps>): Promise<Canvas> => {

    const bannerBase = await generateBannerBase(frame, selectedPaintings, paintingProps)

    const canvas = createCanvas(1500, 500)
    const ctx = canvas.getContext('2d')

    const offsetX = -882 // centers the room

    ctx.drawImage(bannerBase, offsetX, 0)

    return canvas
}
