import Navbar from '../Musts/Navbar.tsx';
// import MyProfile from '../MyProfile/MyProfile';
import Home from '../../Routes/Home/Home';
import {Route, Switch, useLocation} from 'react-router-dom';
import {Footer} from '../Footer/Footer';
import {YourFrames} from '../YourFrames/YourFrames';
import {FramesContextProvider} from '../YourFrames/FramesContext';
import {useEffect, useState} from 'react';
import {RarityPDF} from "./RarityPDF";

const Main = (props) => {
  const [selected, setSelected] = useState(0);
  const [wallet, setWallet] = useState('');

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      document.querySelector(location.hash)?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [location]);

  const myCallbackSelected = (dataFromC) => {
    setSelected(dataFromC);
  };

  const myCallbackWallet = (dataFromC) => {
    setWallet(dataFromC);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar
        connection={props.connection}
        callbackFromParentSelect={myCallbackSelected}
        callbackFromParentWallet={myCallbackWallet}
        selected={selected}
      />

      <div className="flex-1 background-main">
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>

          <Route path="/rarity">
            <RarityPDF />
          </Route>

          <Route path="/your-frames">
            <FramesContextProvider wallet={wallet}>
              <YourFrames />
            </FramesContextProvider>
          </Route>

          {/* <Route path="/profile">
          <MyProfilewallet={wallet}/>
          </FramesContextProvider>
        </Route> */}
        </Switch>
      </div>

      <Footer />
    </div>
  );
};

export default Main;
