import {Frame, IndexedHole, IndexedPainting, Painting, PlaceholderPosition, ShapeProps} from "../helper/frames";
import {Canvas, createCanvas, Image, loadImage, NodeCanvasRenderingContext2D} from "canvas";
import {FirestoreCollection, getDocument, getDownloadURL, StorageCollection} from "../helper/FirebaseFetcher";
import {fetchWallUri} from "./FrameOutputGenerator";
import {getCrop} from "./konva-utils";

type PaintingDetails = Painting & ShapeProps & { image: Image, id: number }

type FrameDetails = {
    name: string,
    image: Image,
    type: 'SQUARE' | 'RECTANGLE' | 'OVAL',
    frameSize: { w: number; h: number },
    paintingSize?: { w: number; h: number },
    paintingEllipseRadius?: { x: number, y: number }
}

const FRAME_DIR: StorageCollection = 'prod/traits/Frame'
const TRAITS_COLLECTION: FirestoreCollection = 'frames-nft-traits-prod'

const fetchFrameDetails = async (name: string): Promise<FrameDetails> => {
    const [details, image] = await Promise.all([
        getDocument(TRAITS_COLLECTION, 'Frame').then(it => it?.[name]),
        getDownloadURL(`${FRAME_DIR}/${name}.png`).then(uri => loadImage(uri, {crossOrigin: "anonymous"}))
    ])
    return ({
        ...details,
        name: name,
        image: image
    })
}

export type PFP = {
    canvas: Canvas,
    name: string
}

export const generatePFPs = async (frame: Frame,
                                   holes: IndexedHole[],
                                   selectedPaintings: IndexedPainting[],
                                   paintingProps: Record<number, ShapeProps>): Promise<PFP[]> => {

    const frameNames: string[] = frame.json?.attributes.filter((a: any) => a.trait_type.startsWith("Frame")).map((a: any) => a.value)

    const paintingPromises: Promise<PaintingDetails>[] = selectedPaintings.map(({id, painting}) =>
        loadImage(painting.uri, {crossOrigin: "anonymous"}).then(image => ({
            ...painting, ...paintingProps[id], image: image, id: id
        }))
    )

    const [wallImage, frames, paintings] = await Promise.all([
        fetchWallUri(frame),
        Promise.all(frameNames.map(fetchFrameDetails)),
        Promise.all(paintingPromises)
    ])

    return paintings.map(p => ({
        canvas: generatePFP(
            wallImage,
            frames[p.id],
            holes.filter(({id}) => id === p.id)[0]!.hole,
            p
        ),
        name: `${p.name} in ${frames[p.id].name} Frame`
    }))
}

const WALL_W = 1920
const WALL_H = 920

const generatePFP = (wall: Image, frame: FrameDetails, placeholder: PlaceholderPosition, painting: PaintingDetails): Canvas => {
    // frame size ratio
    const FR = frame.frameSize.w / frame.frameSize.h

    // max PFP radius
    let r = WALL_H / 2

    // initial frame corner location (center is the middle of the canvas)
    let y = frame.frameSize.h / 2
    let x = frame.frameSize.w / 2

    // fit frame into max PFP radius
    const maxY = Math.sqrt((r * r) / ((FR * FR) + 1))
    if (maxY <= y) {
        y = maxY
        x = FR * y
    } else {
        r = Math.sqrt((x * x) + (y * y))
    }

    x = Math.round(x)
    y = Math.round(y)
    r = Math.round(r)

    // frame corner
    const fc = {x: x, y: y}

    const roomLayer = roomLayerCanvas(wall, frame, fc, r)

    const canvas = createCanvas(r * 2, r * 2)
    const ctx = canvas.getContext('2d')

    ctx.translate(r, r)

    drawPainting(ctx, placeholder, painting, frame, fc)

    ctx.drawImage(roomLayer, -r, -r)

    return canvas
}

const drawPainting = (ctx: NodeCanvasRenderingContext2D, placeholder: PlaceholderPosition,
                      painting: PaintingDetails, frame: FrameDetails, fc: { x: number, y: number }) => {
    const frameScaleRatio = fc.x * 2 / frame.frameSize.w

    let paintingSize: { w: number, h: number } = frame.paintingSize ? frame.paintingSize : {
        w: Math.round(frame.paintingEllipseRadius!.x * 2),
        h: Math.round(frame.paintingEllipseRadius!.y * 2)
    }

    paintingSize = {
        w: paintingSize.w * frameScaleRatio,
        h: paintingSize.h * frameScaleRatio
    }

    const fromEditorScaleRatio = paintingSize.w / placeholder.w

    const shape: ShapeProps = {
        x: Math.round(painting.x * fromEditorScaleRatio),
        y: Math.round(painting.y * fromEditorScaleRatio),
        width: Math.round(painting.width * fromEditorScaleRatio),
        height: Math.round(painting.height * fromEditorScaleRatio),
    }

    const offset = {
        x: Math.round((painting.x - (placeholder.x + placeholder.w / 2)) * fromEditorScaleRatio),
        y: Math.round((painting.y - (placeholder.y + placeholder.h / 2)) * fromEditorScaleRatio),
    }

    const {cropX, cropY, cropWidth, cropHeight} = getCrop(painting.image, shape)

    ctx.drawImage(painting.image,
        cropX, cropY, cropWidth, cropHeight,
        offset.x, offset.y, shape.width, shape.height)
}

const roomLayerCanvas = (wall: Image, frame: FrameDetails, fc: { x: number, y: number }, r: number): Canvas => {
    const canvas = createCanvas(r * 2, r * 2)
    const ctx = canvas.getContext('2d')

    ctx.translate(r, r)

    drawWall(ctx, wall)
    createHole(ctx, frame, fc)

    ctx.shadowColor = 'rgba(0,0,0,0.7)'
    ctx.shadowBlur = 10
    ctx.shadowOffsetX = 2
    ctx.shadowOffsetY = 15

    drawFrame(ctx, frame, fc)

    return canvas
}

const drawFrame = (ctx: NodeCanvasRenderingContext2D, frame: FrameDetails, fc: { x: number, y: number }) => {
    const scaleRatio = fc.x * 2 / frame.frameSize.w

    const imageSize = {
        w: Math.round(frame.image.width * scaleRatio),
        h: Math.round(frame.image.height * scaleRatio),
    }
    const imgX = Math.round(imageSize.w / 2)
    const imgY = Math.round(imageSize.h / 2)

    ctx.drawImage(frame.image, -imgX, -imgY, imageSize.w, imageSize.h)
}

const createHole = (ctx: NodeCanvasRenderingContext2D, frame: FrameDetails, fc: { x: number, y: number }) => {
    const scaleRatio = fc.x * 2 / frame.frameSize.w

    ctx.save()
    ctx.globalCompositeOperation = 'destination-out'

    if (['SQUARE', 'RECTANGLE'].includes(frame.type)) {
        const x = Math.round(frame.paintingSize!.w / 2 * scaleRatio)
        const y = Math.round(frame.paintingSize!.h / 2 * scaleRatio)

        ctx.fillRect(-x, -y, 2 * x, 2 * y)
    } else {
        const rX = Math.round(frame.paintingEllipseRadius!.x * scaleRatio)
        const rY = Math.round(frame.paintingEllipseRadius!.y * scaleRatio)

        ctx.beginPath();
        ctx.ellipse(0, 0, rX, rY, 0, 0, 2 * Math.PI)
        ctx.fill()
    }
    ctx.restore()
}

const drawWall = (ctx: NodeCanvasRenderingContext2D, wall: Image) => {
    const x = WALL_W / 2
    const y = WALL_H / 2

    ctx.drawImage(wall, -x, -y)
}
