import {useWeb3React} from "@web3-react/core"
import {injected} from "./connectors"
import {Button, ButtonProps} from "@material-ui/core";
import React, {FC} from "react";

export const MetamaskButton: FC<ButtonProps> = ({
                                                    children = 'Select Wallet',
                                                    color = 'primary',
                                                    variant = 'contained',
                                                    onClick,
                                                    ...props
                                                }) => {

    const {active, account, activate, deactivate} = useWeb3React()

    async function connect() {
        try {
            await activate(injected)
        } catch (ex) {
            console.log(ex)
        }
    }

    async function disconnect() {
        try {
            deactivate()
        } catch (ex) {
            console.log(ex)
        }
    }

    const icon = () => <img height={24} width={24} src={"metamask-64.png"} alt={"metamask icon"} />

    if (active) {
       return <Button startIcon={icon()} color={color} variant={variant} onClick={disconnect} {...props}>
           Disconnect
        </Button>
    }
    return <Button startIcon={icon()} color={color} variant={variant} onClick={connect} {...props}>
        Connect
    </Button>
}
