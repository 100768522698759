import { css } from '@emotion/css';
import classNames from 'classnames';
import { FC } from 'react';
import { Image } from '../../../../Components/Image/Image';
import room1 from './room-1.png';
import room2 from './room-2.png';

interface AboutProps {
  className?: string;
}

export const About: FC<AboutProps> = ({ className = '' }) => {
  return (
    <section
      id="about"
      className={classNames(
        css`box-shadow: 0rem 0.5rem 0.5rem rgb(0 0 0 / 30%);`,
        className, 'background-light'
      )}
    >
      <div
        className={classNames(
          '  content-box grid grid-cols-1 lg:grid-cols-2 gap-12',
        )}
      >
        <div className="flex flex-col justify-center gap-4">
          <div
            style={{
              paddingTop: '56.25%',
              position: 'relative',
            }}
          >
            <iframe
              width="100%"
              height="auto"
              src="https://www.youtube.com/embed/ewOlES85rvw"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute inset-0 w-full h-full"
            ></iframe>
          </div>
        </div>

        <div className="flex flex-col items-center md:items-start gap-4 xl:pl-8 text-familyPrimary">
          <div className="mb-6">
            <h2 className="text-5xl font-bold text-white">{'About FramesNFT'}</h2>
          </div>

          <p className="text-white">
          FramesNFT is a collection created with a simple mission - we want to allow you to display your NFTs in the coolest way! Each non-digital painting is exhibited in a frame, so why not implement frames to NFTs?
          </p>

          <div >
            <h2 className="text-3xl font-bold text-white">{'Utilities:'}</h2>
          </div>

          <ul>
  <li className="text-white"><a href="https://frames-nft.com/your-frames" target="_blank">Frames Tool</a></li>
  {/* <li className="text-white"><a href="https://nfthodl.zone/" target="_blank">Possibility to mint and stake FramesNFT achievements for $ARTE</a></li> */}
  <li className="text-white">Crosschain - Solana and Ethereum NFTs support</li>
  <li className="text-white">{'Possibility to download FramesNFT with NFTs in Twitter PFP/banner format (using Frames Tool)'}</li>
  <li className="text-white"><a href="https://discord.com/invite/artpunks" target="_blank">Access to ArtPunks holders only Discord</a></li>
  {/* <li className="text-white">In the future: Animated ArtPunks + FramesNFT = ???</li> */}
  <li className="text-white">To provide more fun FramesNFTs have 0% royalties! #0.00%</li>
</ul>
        </div>
      </div>
    </section>
  );
};
