import {decodeMetadata, getMetadataAccount, Metadata} from './Metadata.service';
import {AccountInfo, PublicKey} from '@solana/web3.js';
import _ from 'lodash'
import {Buffer} from "buffer";
import * as anchor from "@project-serum/anchor";

const { TOKEN_PROGRAM_ID } = require('@solana/spl-token');

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

export async function getNFTs(wallet: string | undefined): Promise<Metadata[]> {
  if (!wallet) {
    return []
  }

  const publicKey = new PublicKey(wallet);

  let response = await connection.getParsedTokenAccountsByOwner(publicKey, {
    programId: TOKEN_PROGRAM_ID,
  });
  //console.log('LOG 1')
  let mints = await Promise.all(
    response.value
      .filter(
        (accInfo) =>
          accInfo.account.data.parsed.info.tokenAmount.uiAmount !== 0,
      )
      .filter(
        (accInfo) =>
          accInfo.account.data.parsed.info.tokenAmount.decimals === 0,
      )
      .map(async (accInfo) =>
        getMetadataAccount(accInfo.account.data.parsed.info.mint),
      ),
  );
  //console.log('LOG 2')


  let mintPubkeys = mints.map((m) => new PublicKey(m));

  let multipleAccounts: (AccountInfo<Buffer> | null)[] = [];

  for (const chunk of _.chunk(mintPubkeys, 20)) {
    multipleAccounts = [...multipleAccounts, ...await connection.getMultipleAccountsInfo(chunk)]
  }
  //console.log('LOG 3')

  return multipleAccounts
    .filter(it => it !== null)
    .map(it => decodeMetadata(it!.data)!);
}
