import './App.css';
import {useEffect, useMemo} from 'react';

import * as anchor from '@project-serum/anchor';
import {clusterApiUrl} from '@solana/web3.js';
import {WalletAdapterNetwork} from '@solana/wallet-adapter-base';
import {
    getPhantomWallet,
    getSlopeWallet,
    getSolflareWallet,
    getSolletExtensionWallet,
    getSolletWallet,
} from '@solana/wallet-adapter-wallets';

import {ConnectionProvider, WalletProvider,} from '@solana/wallet-adapter-react';

import {WalletDialogProvider} from '@solana/wallet-adapter-material-ui';
import {createTheme, ThemeProvider} from '@material-ui/core';
import Main from './Components/Main/Main';
import ReactGA from 'react-ga';

import {BrowserRouter} from 'react-router-dom';
import {Web3ReactProvider as EthContextProvider} from '@web3-react/core';
import Web3 from "web3";

ReactGA.initialize('UA-182972542-2');

// const treasury = new anchor.web3.PublicKey(
//   process.env.REACT_APP_TREASURY_ADDRESS!,
// );

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);


const theme = createTheme({
  overrides: {
    MuiButtonBase: {
      root: {
        justifyContent: 'flex-start',
      },
    },
    MuiButton: {
      root: {
        textTransform: undefined,
        padding: '12px 16px',
      },
      startIcon: {
        marginRight: 8,
      },
      endIcon: {
        marginLeft: 8,
      },
    },
  },
});

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    console.log(window.location.pathname + window.location.search);
  }, []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [],
  );

  return (
    <ThemeProvider theme={theme}>
      <EthContextProvider getLibrary={(provider => new Web3(provider))}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          <WalletDialogProvider>
            <BrowserRouter>
              <Main connection={connection} />

              {/* <Home
              candyMachineId={candyMachineId}
              config={config}
              connection={connection}
              startDate={startDateSeed}
              treasury={treasury}
              txTimeout={txTimeout}
            /> */}
            </BrowserRouter>
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
      </EthContextProvider>
    </ThemeProvider>
  );
};

export default App;
